import * as React from "react"
import {Link} from "gatsby"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons"
import * as styles from "./pagination.module.css"

const Pagination = ({numberOfPages, humanPageNumber}) => {
    if (numberOfPages === 1) {
        return null
    }

    return (
        <div className={styles.pagination}>
            <ul>
                <li className={styles.move}>
                    {
                        humanPageNumber <= 1 ? <span><FontAwesomeIcon icon={faChevronLeft}/></span> :
                        <Link to={humanPageNumber === 2 ? `/` : `/${humanPageNumber - 1}`}><FontAwesomeIcon icon={faChevronLeft}/></Link>
                    }
                </li>
                {[...Array(numberOfPages)].map((_, i) => {
                    return (
                        <li key={i}>
                            {
                                i + 1 === humanPageNumber ? <span className={styles.active}>{i + 1}</span> :
                                    <Link to={i === 0 ? `/` : `/${i + 1}`}>{i + 1}</Link>
                            }
                        </li>
                    )
                })}
                <li className={styles.move}>
                    {
                        humanPageNumber >= numberOfPages ? <span><FontAwesomeIcon icon={faChevronRight}/></span> :
                            <Link to={`/${humanPageNumber + 1}`}><FontAwesomeIcon icon={faChevronRight}/></Link>
                    }
                </li>
            </ul>
        </div>
    )
}

export default Pagination
