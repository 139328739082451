import * as React from "react"
import {graphql, Link} from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PostList from "../components/postList";
import Pagination from "../components/pagination";
import * as styles from "./index.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHome } from "@fortawesome/free-solid-svg-icons"

export const pageQuery = graphql`
    query($skip: Int!, $limit: Int!) {
        site {
            siteMetadata {
                title
            }
        }
        allMicrocmsPost(
            sort: { fields: [publishedAt], order: DESC }
            skip: $skip
            limit: $limit
        ) {
            edges {
                node {
                    postId
                    title
                    body
                    eyecatch {
                        url
                    }
                    category {
                        name
                        slug
                    }
                    tag {
                        name
                        slug
                    }
                    publishedAt(formatString: "YYYY年MM月DD日")
                    updatedAt
                }
            }
        }
    }
`

const IndexPage = ({data, pageContext}) => {
    const {numberOfPages, humanPageNumber} = pageContext;

    return (
        <Layout>
            <Seo />
            <nav className="breadcrumb">
                <ol>
                    <li>
                        <Link to="/"><FontAwesomeIcon icon={faHome} /> トップ</Link>
                    </li>
                </ol>
            </nav>
            <div className={styles.postList}>
                <PostList posts={data.allMicrocmsPost.edges.map(edge => edge.node)}/>
            </div>
            <Pagination numberOfPages={numberOfPages}
                        humanPageNumber={humanPageNumber}/>
        </Layout>
    )
}

export default IndexPage
